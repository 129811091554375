.scene_home {
  margin-top: 10rem;
  margin-bottom: 4rem;
  position: relative;
}

.scene_home .scene_home_c {
  height: 60rem;
  margin: 0 auto;
}

.mise-en-scene {
  width: 100%;
  height: 100%;
  background: #000;
  user-select: none;
  overflow-x: hidden;
  overflow-y: visible;
  cursor: grab;

  .grabbing {
    cursor: grabbing;
  }
}

.mise-en-scene-grabbable {
  position: relative;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.mise-en-scene-point {
  position: absolute;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  margin: -.8rem 0 0 -.8rem;
  background-color: #fff;
  cursor: context-menu;
  animation: miseEnScenePointKeyframes 4s infinite ease-in-out;
}

.mise-en-scene-point:hover .mise-en-scene-popover {
  display: block;
}

.mise-en-scene-popover {
  position: absolute;
  display: none;
  background-color: #fff;
  padding: 1.8rem;
  min-width: 23rem;
  border: .1rem solid #d5035e;
  z-index: 1;

  .top-left {
    top: 0;
    left: 0;
  }

  .top-right {
    top: 0;
    right: 0;
  }

  .bottom-left {
    bottom: 0;
    left: 0;
  }

  .bottom-right {
    bottom: 0;
    right: 0;
  }

  a {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-radius: 3.5rem;
    margin-top: 2rem;
  }
}

.mise-en-scene-popover-position,
.mise-en-scene-popover-remove {
  display: none;
}

@keyframes miseEnScenePointKeyframes {
  0% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .2);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, .3);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .2);
  }
}